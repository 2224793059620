import { Breadcrumb, Col, Row } from 'antd';
import DOMPurify from 'isomorphic-dompurify';
import React from 'react';
import { find } from 'lodash';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import NavigateNext from '@material-ui/icons/NavigateNext';

import { Header, InternalContainer, PageContent } from './basic-styles';

import HorizontalSecondaryMenu from '../components/common/horizontal-secondary-menu';
import { NavItem } from '../types/cms/models/nav-item';
import PublicLayout from '../layout/public-layout';
import SectionNavComponent from '../components/common/section-navigation';
import { getRealNodes } from '../utils/contentFilter';
import { navigateTo } from '../state/actions/routing';
import { NavigationWrapper } from '../components/common/page-header';
import { TitleCol } from '../containers/college-finder/styles';

type CollegePrepProps = {
  data: {
    topNavItems: {
      nodes: TypeNavItem[];
    };
    activeNavItem: TypeNavItem;
    sideNavItems: {
      nodes: SectionNavItem[];
    };
    activeSideNavItem: SectionNavItem;
    collegePrep: {
      body: string;
      title: string;
    };
  };
};

type TypeNavItem = {
  slug: string;
  college_prep_type_name: string;
  college_prep_type_value: string;
};

type SectionNavItem = {
  college_prep_section_name: string;
  college_prep_section_value: string;
  collge_prep_section_description: string;
  slug: string;
};

const CollegePrepContainer = styled(InternalContainer)`
  padding-top: 40px;
`;

const StyledHeader = styled(Header)`
  padding-top: 0;
`;

const TitleBreadcrumbsWrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;

// The query used to provide the page data
export const query = graphql`
  query($type: String!, $section: String) {
    topNavItems: allDirectusCollegePrepType {
      nodes {
        slug
        college_prep_type_name
        college_prep_type_value
      }
    }

    activeNavItem: directusCollegePrepType(college_prep_type_name: { eq: $type }) {
      college_prep_type_name
      college_prep_type_value
      slug
    }

    collegePrep: directusCollegePrep(college_prep_type: { college_prep_type_name: { eq: $type } }) {
      body
      title
    }

    sideNavItems: allDirectusCollegePrepSection(
      filter: { college_prep_type: { college_prep_type_name: { eq: $type } }, status: { eq: "published" } }
    ) {
      nodes {
        college_prep_section_name
        college_prep_section_value
        slug
      }
    }

    activeSideNavItem: directusCollegePrepSection(college_prep_section_name: { eq: $section }) {
      college_prep_section_name
      college_prep_section_value
      collge_prep_section_description
      slug
    }
  }
`;

const CollegePrep = ({
  data: { topNavItems, activeNavItem, sideNavItems, activeSideNavItem, collegePrep },
}: CollegePrepProps) => {
  const dispatch = useDispatch();

  const mappedMenuItems: NavItem[] = getRealNodes(topNavItems).map((item: TypeNavItem) => {
    return {
      value: item.college_prep_type_value,
      name: item.college_prep_type_name,
      slug: item.slug,
    };
  });
  const typeItem: NavItem = {
    value: activeNavItem.college_prep_type_value,
    name: activeNavItem.college_prep_type_name,
    slug: activeNavItem.slug,
  };

  const mappedTertiaryMenu: NavItem[] = getRealNodes(sideNavItems).map((item: SectionNavItem) => {
    return {
      value: item.college_prep_section_value,
      name: item.college_prep_section_name,
      slug: item.slug,
    };
  });
  const sectionItem: NavItem = {
    value: activeSideNavItem.college_prep_section_value,
    name: activeSideNavItem.college_prep_section_name,
    slug: activeSideNavItem.slug,
  };

  const handleSecondaryMenuClick = (e: any) => {
    const activeItem = find(mappedMenuItems, item => {
      return item.name === e.key;
    });

    if (activeItem) {
      dispatch(navigateTo(activeItem.slug));
    }
  };

  const handleTertiaryMenuClick = (name: string, slug: string) => {
    const activeItem = find(mappedTertiaryMenu, item => {
      return item.name === name;
    });

    if (activeItem) {
      dispatch(navigateTo(slug));
    }
  };

  const generateBreadcrumb = (name?: string) => {
    return [
      <Link key="1" to="/">
        {name}
      </Link>,
      'Resources: College Prep',
    ];
  };

  return (
    <PublicLayout seoTitle="College Prep">
      <TitleBreadcrumbsWrapper>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb('Home').map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        <TitleCol>College Prep</TitleCol>
      </TitleBreadcrumbsWrapper>
      <HorizontalSecondaryMenu activeItem={typeItem} handleClick={handleSecondaryMenuClick} items={mappedMenuItems} />
      <CollegePrepContainer>
        <Row justify="space-between">
          {mappedTertiaryMenu.length > 0 && (
            <Col xs={24} lg={6}>
              <SectionNavComponent
                navItems={mappedTertiaryMenu}
                activeItem={sectionItem}
                handleClick={handleTertiaryMenuClick}
              />
            </Col>
          )}

          {collegePrep ? (
            <Col xs={24}>
              <StyledHeader>{collegePrep.title}</StyledHeader>
              <PageContent
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(collegePrep.body, {
                    ADD_TAGS: ['iframe'],
                    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
                  }),
                }}
              />
            </Col>
          ) : activeSideNavItem ? (
            <Col xs={24} lg={18}>
              <StyledHeader>{activeSideNavItem.college_prep_section_value}</StyledHeader>
              <PageContent
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(activeSideNavItem.collge_prep_section_description),
                }}
              />
            </Col>
          ) : null}
        </Row>
      </CollegePrepContainer>
    </PublicLayout>
  );
};

export default CollegePrep;
